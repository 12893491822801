import React from 'react';
import RootLayout from "@/app/layout";
import Head from 'next/head';
import { HeaderSection } from '@/app/components/sections/HeaderSection';
import data from '../data/data.json';

export const metadata = {
  title: 'Thursdays',
  description: 'Die Webseite von ein paar Leuten die sich regelmässig am Donnerstag treffen.',
  canonicalUrl: 'https://thursdays.ch'
}



export async function getStaticProps() {
  return { props: { data } }
}


export default function Home({ data }) {
  const { Header } = data;

  return (
    <RootLayout>
      <Head>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={metadata.keywords} />
        <link rel="canonical" href={metadata.canonicalUrl} />
      </Head>
      <HeaderSection data={Header} />
    </RootLayout>
  );
}
